@import '../../globals.sass';
@import '~bootstrap/dist/css/bootstrap.min.css';

.header {
  position: fixed;
  display: flex;
  top: 0;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  width: 100%;
  box-shadow: 0px 0px 2px 2px lightgray;
  background-color: $header-color;
  height: $header-height;
  z-index: 1000;

  #logo {
    margin-left: 45px;
  }

  .saloon {
    @include main-text();

    font-weight: 600;
    float: left;
    font-size: 2.5em;
  }

  #slogan {
    position: relative;
    top: -10px;
    left: 10px;

    clear: both;
    font-weight: normal;
    font-size: 0.75em;
    font-style: italic;
    float: right;
  }

  li {
    list-style: none;
  }

  .login-account {
    a {
      @include main-text();

      &:first-of-type {
        margin-right: 20px;
      }
    }

    font-weight: 300;
    font-size: 1.2em;
    flex: unset;
    margin-right: 35px;
    padding-left: 0.5em;

    .btn-secondary,
    .dropdown-toggle,
    .btn {
      @include counter-bootstrap();

      margin-right: 50px;
      margin-left: 10px;
      color: $main-color !important;
      background: none !important;
    }
  }

  .dropdown-item {
    outline: none;
  }

  .sub-item {
    position: relative;
    cursor: pointer;

    width: 100%;
    height: 50px;
    padding: 5px;

    .title {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      left: 5%;

      width: 60px;
      display: inline-block;
      text-overflow: ellipsis;
      text-anchor: middle;
      white-space: nowrap;
      overflow: hidden;
    }

    .buttons {
      position: absolute;
      top: 50%;
      right: 5%;
      transform: translate(0, -50%);

      text-anchor: middle;
    }
  }

  .search-wrapper {
    flex: 0.8;
    flex-shrink: 0.8;
    flex-direction: row;
    display: flex;
    justify-content: center;

    input {
      height: 2.2em;
      font-size: 0.9em;
      flex: 0.6;
      padding-left: 10px;
      outline: none;
    }

    div {
      text-align: center;
      background-color: $main-color;
      padding: 4px;
      cursor: pointer;
    }

    .i-wrapper {
      padding: 2px;
    }

    .body {
      flex: 0.2;
      font-size: 0.2em;
    }
  }

  a {
    &:hover {
      color: $secondary-color !important;
    }
  }
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

@media (max-width: 499px) {
  .header {
    height: calc(#{$header-height} - 10px);

    #logo {
      margin-left: 20px;
    }

    .login-account {
      margin-right: 20px;
    }

    .search {
      display: none;
    }
  }

  a {
    color: black;
  }
}
