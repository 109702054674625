@import './globals.sass';

$edge-padding: 20px;

.content {
  margin-left: calc(#{$navigation-width} + #{$edge-padding});
  margin-top: calc(#{$header-height} + #{$edge-padding});

  width: calc(100vw - #{$edge-padding} - #{$navigation-width});
  height: calc(100vh - #{$edge-padding} - #{$header-height});
}

@media (max-width: 499px) {
  $edge-padding: 10px;

  .content {
    margin-left: calc(#{$edge-padding});
    margin-top: calc(#{$header-height} + #{$edge-padding});

    width: calc(100vw - #{$edge-padding});
    height: calc(100vh - #{$edge-padding} - #{$header-height});
  }

  .nav {
    display: none;
  }
}
