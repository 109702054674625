@import '../../globals.sass';

.navigation {
  position: fixed;
  top: 0;
  height: 100%;
  margin-top: $header-height;
  padding-top: 25px;
  box-shadow: 0px 0px 1px 1px lightgray;
  background-color: $navigation-color;
  width: $navigation-width;

  .links {
    display: block;
    margin-top: 30px;

    .nav-item {
      @include main-text();

      display: block;
      font-family: $main-body-font;
      padding: 10px 0 10px 30px;
      font-size: 1.2em;
      font-weight: 200;

      &:hover,
      &.selected {
        background-color: $main-color;
        color: white;
      }
    }

    a {
      font-style: normal;
    }
  }
}
