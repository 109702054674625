@import "../../globals.sass";

$contributor-width: 180px;
$contributor-color: white;

@mixin title-text() {
  color: rgb(36, 36, 36);
  font-family: Arial;
  font-weight: bold;
  text-align: center;
}

.post {
  height: 100%;

  .editor {
    width: calc(100% - #{$contributor-width} - 20px);
    height: 100%;

    .post-title {
      @extend .clear-input;
      @include title-text();

      margin: 0 auto;
      font-size: 1.7em;
      width: 80%;
    }

    .quill {
      margin: 0 auto;
      margin-top: 20px;
      width: 95%;
      height: 80%;

      .ql-toolbar {
        border: none;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.13);
        z-index: 10;
        position: relative;
      }

      .ql-container {
        padding: 5px 3px;
        font-size: 0.95em;
        z-index: 1;
        height: 100%;
        background: #fff;
        border: none;
        box-shadow: 0 9px 0px 0px white, 0 -9px 0px 0px white, 0px 3px 6px rgba(0, 0, 0, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.1);
      }
    }
  }

  .contributors {
    @include title-text();

    position: fixed;
    top: 0;
    right: 0;
    margin-top: $header-height;
    padding-top: 1.2em;
    box-shadow: 0px 0px 7px 1px lightgray;
    background-color: $contributor-color;
    width: $contributor-width;
    height: calc(100% - #{$header-height});
    font-size: 1.3em;
  }

  .post-button {
    width: inherit;
    height: 100px;
    background: #fff;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.16);
    margin: auto auto;
    cursor: pointer;
    bottom: 0;
    display: flex;
    position: absolute;
    justify-content: center;
    color: $main-color;
    align-items: center;

    &:hover {
      color: $secondary-color;
    }

    .text {
      font-family: Arial;
      font-weight: bold;
      font-style: italic;
      font-size: 1.2em;
      text-align: center;
      flex: 1;
    }
  }
}