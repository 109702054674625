.about {
  height: 100%;
  padding-right: 20px;
  margin-bottom: 20px;
  text-align: center;

  .pad-top {
    padding-top: 20px;
  }

  .bold-y-margin {
    margin: 15px 0;
    font-weight: bold;
    font-size: 1.2em;
  }

  #title {
    font-size: 1.8em;
    text-align: center;
  }

  .editor {
    display: inline-block;
    width: calc(100% - 200px);

    .quill {
      margin: 0 auto;
      margin-top: 20px;
      width: 95%;
      height: 100%;

      .ql-toolbar {
        border: none;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.13);
        z-index: 10;
        position: relative;
        height: 7%;
      }

      .ql-container {
        padding: 5px 3px;
        font-size: 0.95em;
        z-index: 1;
        height: 100%;
        background: #fff;
        border: none;
        box-shadow: 0 9px 0px 0px white, 0 -9px 0px 0px white,
          0px 3px 6px rgba(0, 0, 0, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.1);

        text-align: center;
        font-size: 1.1em;
        padding: 20px 100px;

        .tip {
          text-align: left;
          margin-left: 10%;
          margin-bottom: 10px;
        }

        .last {
          text-align: left;
          margin-left: 25%;
          margin-top: 20px;
          margin-bottom: 20px;
        }

        .fun {
          text-align: left;
          margin-left: 40%;
        }
      }
    }
  }
}
