@import '../../globals.sass';

.user-name {
  @include main-text();
  font-size: 1.8em;
  font-weight: bold;
  margin-bottom: 20px;
}

.section {
  @include main-text();
  font-size: 1.3em;
  font-weight: 300;
  margin: 0 0 20px 5px;
}
