@import "../../globals.sass";

.form {
  text-align: center;
  font-family: $main-body-font;
  margin: 0 auto;

  .field-title {
    text-align: left;
    margin: 0 auto;
    width: calc(40% + 16px);
    margin-top: 20px;
    font-family: $main-body-font;
    font-size: 0.9em;
    font-weight: 600;
  }

  .error {
    @extend .field-title;

    margin-top: 2px;
    font-size: 0.8em;
    font-style: italic;
    color: #ac1717;
    font-weight: initial;
  }

  input {
    padding: .5rem;
    font-size: 16px;
    width: 40%;
    display: block;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin: auto;
    min-width: 240px;

    &:focus {
      border-color: #007eff;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 0 3px rgba(0, 126, 255, 0.1);
      outline: none;
    }

    &.error {
      border: 1px solid red;
    }

  }

  button {
    @include submit-button();
    margin: 20px auto;
  }

  .switch-here {
    font-style: italic;

    a {
      color: rgb(68, 112, 255);

      &:hover {
        color: blueviolet;
      }
    }
  }
}