@import '../../globals.sass';

$panel-padding: 20px;
$sliding-speed: 0.5s;

// Content Variables
$content-width: calc(100% - 60px - #{$panel-padding});

.conversation {
  display: flex;
  height: 100%;
  flex-direction: column;

  .discussion {
    display: block;
    flex: 1;
    width: $content-width;
    white-space: pre-wrap;
    overflow: scroll;

    .inner {
      box-sizing: content-box;
      margin: 0 auto;
      flex-direction: column;
      display: flex;
      max-width: 65%;
      margin-top: 30px;
      justify-content: space-evenly;

      .item {
        font-size: 1.05em;
        font-family: Arial;

        span {
          &:first-of-type {
            font-weight: bold;
            font-style: italic;
            color: #44127e;
          }
        }

        p {
          display: block;
          margin-bottom: 0;

          &:first-of-type {
            display: inline;
          }
        }
      }

      .break {
        width: 40%;
        height: 2px;
        background-color: #6f6f6f99;
        margin: 30px auto;
        border-radius: 100px;

        &:last-of-type {
          display: none;
        }
      }

      &.addable {
        .break:last-of-type {
          display: block;
        }
      }
    }

    .editor-wrapper {
      position: relative;

      .quill {
        width: 85%;
        margin: 30px auto;
        margin-bottom: 60px;
      }

      .post-button {
        position: absolute;
        top: 12px;
        right: calc(7.5% + 12px);
        font-weight: bold;
        color: #002366;
        font-size: 1em;
        font-family: Arial;
        cursor: pointer;
        user-select: none;

        &:hover {
          color: $secondary-color;
        }
      }
    }
  }

  .d-title {
    height: 65px;
    margin-left: -20px;
    width: calc(#{$content-width} + 40px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    border-top: 2px solid rgb(175, 175, 175);
    text-align: center;

    .text {
      margin-top: 4px;
      font-family: 'Helvetica Neue';
      font-weight: bold;
      font-size: 1.15em;
      text-align: center;
      color: #000;
    }

    .vote {
      font-size: 1.1em;
      display: flex;
      justify-content: center;
      align-items: center;

      .amount {
        padding: 0 3px;
      }

      .thumb {
        @extend .likes;
      }
    }

    .join {
      font-weight: bold;
      color: $main-color;
      font-size: 1em;
      font-family: Arial;
      cursor: pointer;
      user-select: none;
      text-align: center;

      &:hover {
        color: $secondary-color;
      }
    }

    .views {
      font-family: Arial;
      font-size: 1.1em;
    }
  }

  .sidebar {
    .comment-section {
      height: 55%;
      overflow-y: scroll;

      .comment {
        position: relative;
        height: 20%;
        min-height: 120px;
        font-size: 0.9em;
        padding: 10px;

        .meta {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .contributor {
            font-weight: 400;
          }

          .time {
            font-style: italic;
          }
        }

        .body {
          margin-top: 5px;
          font-size: 1.15em;
        }
      }
    }

    .add {
      position: absolute;
      display: flex;
      bottom: 100px;
      right: 0;
      left: 0;
      margin: auto;
      height: 18%;
      width: 80%;
      min-width: 220px;
      min-height: 120px;

      textarea {
        resize: none;
        flex: 1;
        padding: 10px;
        font-size: 0.9em;
        font-family: $main-body-font;
        border-radius: 7px;
        border: 1px solid $main-color;

        &:focus {
          border-color: #007eff;
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
            0 0 0 3px rgba(0, 126, 255, 0.1);
          outline: none;
        }
      }

      button {
        @include submit-button();

        position: absolute;
        text-align: right;
        right: 10px;
        bottom: 10px;
        font-size: 12px;
        padding: 9px 10px;
        border-radius: 12px;
      }
    }
  }
}

.likes {
  &:hover,
  &.upvoted {
    cursor: pointer;
    fill: $main-color;

    svg {
      fill: $main-color;
    }

    color: $main-color;
  }
}

@media (max-width: 750px) {
  $panel-padding: 20px;

  $content-width: calc(100% - 15px - #{$panel-padding});

  .conversation {
    .discussion {
      width: $content-width;

      .inner {
        box-sizing: content-box;
        margin: 0 auto;
        flex-direction: column;
        display: flex;
        max-width: 85%;
        margin-top: 30px;
        justify-content: space-evenly;
      }
    }

    .d-title {
      margin-left: 10px;
      //width: calc(#{$content-width} + 40px);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      border-top: 2px solid rgb(175, 175, 175);
      text-align: center;
    }
  }
}
