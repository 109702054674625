@import "../../globals.sass";

@mixin title-text() {
  color: rgb(36, 36, 36);
  font-family: Arial;
  font-weight: bold;
  text-align: center;
}

.contributors {
  @include title-text();
  font-size: 1.3em;

  .section {
    width: 75%;
    margin: 0 auto;
    text-align: left;
    padding-bottom: 10px;

    .title {
      margin-top: 15px;
      color: #2C2C2C;
      font-size: 0.6em;
    }

    .users {
      max-height: 80px;
      overflow: scroll;

      .user {
        margin-top: 5px;
        font-size: 0.6em;
        line-height: 25px;
        font-weight: normal;
        font-family: Arial, Helvetica, sans-serif;

        .name {
          margin-left: 10px;
        }
      }
    }
  }

  .invite {
    padding-top: 25px;
    font-weight: bold;
    width: 75%;
    margin: 0 auto;

    .title {
      text-align: left;
      color: #2C2C2C;
      font-size: 0.7em;
      font-family: Arial;
    }

    .field {
      margin-top: 5px;

      input {
        font-size: 0.6em;
        color: $secondary-color;
        font-weight: bold;
        float: left;
        width: 75%;
        margin-right: 5px;
        border-bottom: 2px solid black;
      }

      .submit {
        display: block;
        width: 0.6em;
        cursor: pointer;
        height: 19px;
      }
    }
  }
}