// Global colors
$main-color: #1f1f1f;
$secondary-color: #1954c6;

$header-color: #ffffff;
$navigation-color: #FAFAFA;

// Global fonts
$main-font: Arial, Helvetica, sans-serif;
$main-body-font: Arial, Helvetica, sans-serif;

// Global values
$navigation-width: 170px;
$header-height: 90px;

@mixin main-text {
  color: $main-color;
  font-family: $main-font; }

@mixin submit-button {
  max-width: 150px;
  padding: 10px 15px;
  border-style: none;
  border-radius: 5px;
  background-color: $main-color;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  font-size: 17px;
  font-weight: 500;
  color: #fff;
  display: block;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;

  &:hover {
    background-color: $secondary-color; } }

.clear-input {
  display: block;
  box-shadow: none;
  outline: none;
  border: none; }

.column-page {
  margin: auto auto 0 auto;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  width: 90%; }

@mixin counter-bootstrap {
  color: inherit !important;
  box-shadow: inherit !important;
  border: none !important;
  padding: 0 !important;
  cursor: pointer !important;
  outline: inherit !important; }

body {
  margin: 0; }
