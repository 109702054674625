.card-row {
  margin-bottom: 80px;

  &.flex {
    position: relative;
    display: flex;
    justify-content: space-around;

    &:first-of-type {
      margin-top: 40px;
    }

    &:last-of-type {
      margin-bottom: 40px;
    }
  }

  &.noflex {
    white-space: nowrap;
    overflow: scroll;
    padding: 5px;

    .xcard {
      vertical-align: top;
      white-space: normal;
      display: inline-block;
      margin-right: 35px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .xcard {
    position: relative;
    width: 290px;
    height: 200px;
    background: #fff;
    cursor: pointer;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);

    .title {
      font-family: Helvetica;
      margin: auto;
      font-weight: 600;
      font-size: 1.05em;
      margin-top: 15px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 250px;
      text-align: center;
    }

    .sb-avatar {
      margin-left: 10px;
      display: block;
      margin-top: 10px;

      &:nth-of-type(3) {
        margin-left: -10px;
      }

      float: left;
      white-space: normal;

      .sb-avatar__text {
        border: 3px solid white;
        margin-top: -3px;
      }
    }

    .discussed-by {
      display: block;
      clear: left;
      width: 95%;
      padding-left: 13px;
      padding-right: 13px;

      font-family: Arial;
      font-style: italic;
    }

    .views,
    .age {
      position: absolute;
      bottom: 5px;

      font-family: Arial;
      font-size: 1em;
      font-style: italic;
    }

    .views {
      left: 10px;
    }

    .age {
      right: 10px;
    }
  }

  .connector {
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
    width: 75%;
    height: 60px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: -5;
  }
}

@media (max-width: 499px) {
  .card-row {
    margin-bottom: 50px;
  }
}
